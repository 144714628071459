import ClipboardJS from "clipboard";
import tippy from 'tippy.js';

export function article()
{
    // Сворачивание/разворачивание статей
    const articles = document.getElementsByClassName("article-item");
    const shareUrlButtons = document.getElementsByClassName("share-url-btn");
    if (articles.length > 0) {
        for (let i = 0; i < articles.length; i++) {
            articles[i].addEventListener('click', function (e) {
                // e.preventDefault();
                let targetElement = e.target;
                if (this.classList.contains('article--open') === false) {
                    this.classList.toggle('article--open');
                } else {
                    while (targetElement && targetElement !== this) {
                        if (targetElement.classList.contains('article-action-toggle')) {
                            this.classList.toggle('article--open');
                            return;
                        }
                        targetElement = targetElement.parentNode;
                    }
                }
            });
        }
    }

    // Инициализация кнопки копирования ссылки
    if (shareUrlButtons.length > 0) {
        new ClipboardJS('.share-url-btn');
        const templateTooltipClipboard = document.getElementById('tooltip_clipboard');
        tippy('.share-url-btn', {
            content: templateTooltipClipboard.innerHTML,
            allowHTML: true,
            trigger: 'click',
            theme: 'custom',
            arrow: false,
            onShow(instance) {
                setTimeout(() => {
                    instance.hide();
                }, 1000);
            }
        });
    }
}
