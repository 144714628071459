// Генерация уникального идентификатора
function generateUniqueId() {
    return 'xxxx-xxxx-4xxx-yxxx-xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

// Получение уникального идентификатора
function getUniqueId() {
    let uniqueId = document.cookie.split('; ').find(row => row.startsWith('uniqueId='));
    if (!uniqueId) {
        uniqueId = generateUniqueId();
        const now = new Date();
        const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
        const secondsUntilEndOfDay = (endOfDay - now) / 1000;
        document.cookie = `uniqueId=${uniqueId}; path=/; max-age=${secondsUntilEndOfDay}`;
    } else {
        uniqueId = uniqueId.split('=')[1];
    }
    return uniqueId;
}

// Сбор информации о пользователе
function collectUserInfo() {
    return {
        uniqueId: getUniqueId(),
        digest: window.digest ?? null,
        userAgent: navigator.userAgent,
        language: navigator.language,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        referrer: document.referrer,
        url: window.location.href
    };
}

// Отправка информации о пользователе
export function sendUserInfo() {
    const endpoint = window.collect_url ?? null;
    const userInfo = collectUserInfo();
    if (!endpoint) return;
    fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userInfo)
    })
      .catch((error) => console.error('Error:', error));
}
